import hashScrollTo from '../utils/hashScrollTo';
import scrollTo from '../utils/scrollTo';

const navbarThemeToggle = () => {
  const navbar = document.querySelector('.navbar');

  if (window.pageYOffset > 200 && !navbar.classList.contains('navbar-dark')) {
    navbar.classList.remove('navbar-light', 'py-4');
    navbar.classList.add('navbar-dark', 'bg-white', 'shadow-sm');
  }

  if (window.pageYOffset < 201 && !navbar.classList.contains('navbar-light')) {
    navbar.classList.remove('navbar-dark', 'bg-white', 'shadow-sm');
    navbar.classList.add('navbar-light', 'py-4');
  }
};

const navdalToggle = () => {
  const navdal = document.getElementById('navdal');

  document.getElementById('navdal-toggler').addEventListener('click', (e) => {
    e.preventDefault();
    navdal.classList.add('open');
  });

  document.getElementById('navdal-close').addEventListener('click', (e) => {
    e.preventDefault();
    navdal.classList.remove('open');
  });

  navdal.querySelectorAll('.nav-link').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      navdal.classList.remove('open');
    });
  });
};

const scrollDown = () => {
  const heroNextSection = document.querySelector('main .hero + .section');

  document.querySelector('.scroll-down').addEventListener('click', (e) => {
    e.preventDefault();
    scrollTo(heroNextSection.offsetTop, 350);
  });
};

const anchorScroll = () => {
  hashScrollTo(window.location.hash);

  document.querySelectorAll('a[href*="#"]').forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault();
      hashScrollTo(anchor.hash);
    });
  });
};

export default () => {
  anchorScroll();
  navbarThemeToggle();
  navdalToggle();
  scrollDown();

  window.addEventListener('scroll', navbarThemeToggle);
};
