export default (offsetTop, duration = 700) => {
  const start = window.performance.now();
  const { pageYOffset } = window;
  const scrollTop = offsetTop - pageYOffset;

  const scrollingTo = (now) => {
    const elapsed = now - start;
    const progress = Math.min(elapsed / duration, 1);

    window.scrollTo(0, pageYOffset + scrollTop * progress);

    if (progress !== 1) {
      window.requestAnimationFrame(scrollingTo);
    }
  };

  scrollingTo(start);
};
