import common from './controllers/common';

const controllers = {};

document.addEventListener('DOMContentLoaded', () => {
  common();

  document.querySelectorAll('[data-controller]').forEach((element) => {
    const { controller } = element.dataset;

    if (typeof controllers[controller] === 'function') {
      controllers[controller]();
    }
  });
});
