import scrollTo from './scrollTo';

export default (hash) => {
  if (!hash) {
    return;
  }

  const scrollToElement = document.querySelector(`[data-hash="${hash}"]`);

  if (scrollToElement) {
    scrollTo(scrollToElement.offsetTop, 350);
  }
};
